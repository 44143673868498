import type { PhaseManagerCartPhase } from '@core-theme/types/cart'
import type { RouteLocationNormalized } from 'vue-router'
import {
    CART_PAYMENT_RETURN_QUERY_ORDER_ID
} from '../../shared/constants'
import { getOrdersApiService } from '@simploshop-services/Orders.service'
import { ORDER_EMBEDS } from '../assets/ts/constants/cart'

/*
  This middleware is being checked in the cart store (Phase Manager) to determine whether we're on
  a cart route or not - so that we only redirect the customer on forced phase change when they're
  in cart and not elsewhere on the page.
  Update the middleware name there when renaming this / refactor it later.
 */

export default defineNuxtRouteMiddleware(async (to, from) => {
    // skip middleware on initial client load
    const nuxtApp = useNuxtApp()
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

    const cartStore = useCartStore()
    const { switchTo, canSwitchTo, phases, phase, fetchCartIfNotLoaded } = useCart()
    const localePath = useLocalePath()

    if (import.meta.server) {
        await fetchCartIfNotLoaded()
    }

    const newPhase = phases.value.find((phase: PhaseManagerCartPhase) => localePath({ name: phase.routeName }) === to.path)
    // if the phase was not found, continue to normal routing
    if (!newPhase) return

    // if we're going to the summary page from the payment provider (order id is in the url)
    if (newPhase.id === CartPhase.SUMMARY && !cartStore.latestOrder) {
        const orderId = getOrderId(to)
        let shouldCreateDummyOrder = orderId !== null

        if (orderId !== null) {
            try {
                // fetch the order and set it to the store to fulfill the requirements of the summary page
                const response = await getOrdersApiService()
                    .embed(ORDER_EMBEDS)
                    .forId(orderId)
                    .get()

                cartStore.latestOrder = response.getItem()
                shouldCreateDummyOrder = !cartStore.latestOrder
            } catch (e) {
                if (e instanceof ApiResponseError && e.isStatus([404, 403, 401])) {
                    shouldCreateDummyOrder = false
                } else {
                    console.error('[middleware/cart]: Failed to fetch order when navigating to the cart summary page.', e)
                }
            }
        }

        // if we still do not have the latest order, the order status is unknown
        if (shouldCreateDummyOrder) {
            // create a dummy order that indicates that the payment status is unknown
            cartStore.latestOrder = OrderModel.create({
                attrs: {
                    [OrderModel.ATTR_ID]: -1,
                },
            })
        }
    }

    if (!canSwitchTo(newPhase)) {
        return navigateTo(localePath({ name: phase.value?.routeName }), { redirectCode: 307 })
    }

    // TODO: refactor later
    const wasCartRoute = from.matched.some(route => route.meta.middleware === 'cart')

    // switch to the new phase right away if on the server or not on a cart route
    if (import.meta.server || !wasCartRoute) {
        switchTo(newPhase)
    } else {
        // in case of a cart route, we want to change the phase only after the page
        // async data has been resolved & the new page mounted
        useOnPageLoaded(() => {
            switchTo(newPhase)
        })
    }
})

function getOrderId(route: RouteLocationNormalized) {
    const orderId = route.query[CART_PAYMENT_RETURN_QUERY_ORDER_ID]
    if (orderId && !Array.isArray(orderId)) {
        const parsedOrderId = parseInt(orderId)
        return isNaN(parsedOrderId) ? null : parsedOrderId
    }
    return null
}
